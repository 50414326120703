import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from './pages/user/Dashboard';
import PrivateRoute from './pages/user/PrivateRoute';
import Api from './api';
import Profiles from './pages/main/Auth/Profiles';
import Login from './pages/Login';
import Logout from './pages/main/Auth/Logout';
import Main from './pages/main/Main';
import Award from './pages/user/Award';
import Workshops from './pages/user/Workshops';
import Profil from './pages/user/Profil';

function App() {
  return (
    <>
      <Api>
        <Router>
          <Profiles>
            <Routes>
              <Route element={<PrivateRoute />}>
                <Route path='/user/dashboard' element={<Dashboard />} />
                <Route path='/user/award' element={<Award />} />
                <Route path='/user/workshop' element={<Workshops />} />
                <Route path='/user/profil' element={<Profil />} />
              </Route>
            </Routes>
          </Profiles>
          <Routes>
            <Route path='/logout' element={<Logout />} />
            <Route path='/:nama' element={<Main />} />
            <Route exact path='/' element={<Login />} />
          </Routes>
        </Router>
      </Api>
    </>

  );
}

export default App;
