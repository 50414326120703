import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import '../../assets/css/app.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Navbar from './nav/Navbar';
import Footer from '../main/nav/Footer';
import { apiContext } from '../../api';
import countryList from 'react-select-country-list';
import { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useRef } from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { UseTableSearch } from './nav/UseTableSearch';
const mdTheme = createTheme();
const columns = [
    { id: 'id', label: 'No', width: 2 },
    { id: 'judul', label: 'Nama Award', minWidth: 13 },
    { id: 'nama', label: 'Sertifikat', minWidth: 13 },
    { id: 'option', label: 'Option', minWidth: 5 },
];
function Award() {
    const { axios } = React.useContext(apiContext);
    const user_id = localStorage.getItem("user_id");
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    var inputRef = useRef(undefined);
    const date = new Date();
    const [jurnal, setJurnal] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [id, setId] = React.useState(null);
    const [judul, setJudul] = React.useState("");
    const [tanggal, setTanggal] = React.useState("");
    const [nama, setNama] = React.useState("");
    const [negara, setNegara] = React.useState("");
    const [lokasi, setLokasi] = React.useState("");
    const [edit, setEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [options, setOptions] = React.useState(countryList().getData());
    const [cari, setCari] = React.useState("");
    const countryData = useMemo(() => countryList().getData(), [])

    const changeHandler = value => {

        setNegara(value)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    var count = 0;

    const fetchData = async () => {
        const response = await axios.get(`/award-get/${user_id}`);
        const data = response.data;
        if (data) {
            setJurnal(data);
        }

    }
    const editData = async (id) => {
        setLoading(id);
        const response = await axios.get(`/award-edit/${id}`);
        const data = response.data;
        if (data) {
            setEdit(true);
            setId(data.id);
            setNama(data.nama);
            setTanggal(data.tanggal);
            setJudul(data.judul);
            setNegara(data.negara);
            setLokasi(data.lokasi);
            toggleDrawer();
            setLoading(false);
        }
    }
    const deleteData = (event, id) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Data tidak dapat dikembalikan jika dihapus",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus'
        }).then((result) => {
            if (result.isConfirmed) {
                deleted(event, id);
            }
        })

    }
    React.useEffect(() => {
        fetchData();

    }, [])
    React.useEffect(() => {
        if (cari) {
            const reqData = countryData.map((item, index) => {
                if (item.label.toLowerCase().indexOf(cari.toLowerCase()) >= 0)
                    return countryData[index];
                return null;
            });
            setOptions(
                reqData.filter(item => {
                    if (item) return true;
                    return false;
                })
            );
        } else setOptions(countryData);
    }, [cari])
    const resetInput = () => {
        setId(null);
        setJudul("");
        setNama("");
        setTanggal("");
        setNegara("");

    }
    const cancelEdit = () => {
        resetInput();
        setEdit(false);
        toggleDrawer();

    }
    const deleted = async (event, id) => {
        event.preventDefault();
        await axios.delete(`/award-delete/${id}`)
            .then(
                response => {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Berhasil Dihapus',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    fetchData();
                }

            )
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Mohon coba kembali',
                })
            });

    }
    const submit = async event => {
        event.preventDefault();
        const data = {
            judul: judul,
            tanggal: tanggal,
            nama: nama,
            negara: negara,
            lokasi: lokasi,
        }
        if (edit) {
            await axios.put(`/award-update/${id}`, data)
                .then(
                    response => {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Berhasil disimpan',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        fetchData();
                        resetInput();
                        setEdit(false);
                        toggleDrawer();
                        setLoading(false);
                    }

                )
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Gagal',
                        text: 'Mohon coba input kembali',
                    })
                });

        } else {
            await axios.post('/award-post', data)
                .then(
                    response => {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Berhasil disimpan',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        fetchData();
                        resetInput();
                        toggleDrawer();
                        setLoading(false);
                    }

                )
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Gagal',
                        text: 'Mohon coba input kembali',
                    })
                });

        }

    }

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar title={'Award'} />

                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    {
                        open ?
                            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                                <Paper className='bg-card' sx={{ width: '100%', overflow: 'hidden', p: 2 }}>
                                    <form onSubmit={submit}>
                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <div className='form-group mb-2'>
                                                    <TextField required variant="outlined" label="Nama Penghargaan" value={judul} className="w-100" onChange={e => setJudul(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='form-group mb-2'>
                                                    <TextField
                                                        label="Tanggal"
                                                        type="date"
                                                        sx={{
                                                            width: '100%'
                                                        }}
                                                        value={tanggal}
                                                        onChange={e => setTanggal(e.target.value)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <div className='form-group mb-2'>
                                                    <TextField required variant="outlined" label="Pemberian oleh" value={nama} className="w-100" onChange={e => setNama(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='form-group mb-2'>
                                                    <FormControl className={` w-100`}>
                                                        <InputLabel>Pilih Negara</InputLabel>
                                                        <Select
                                                            label="Pilih Negara"
                                                            value={negara}
                                                            defaultValue=""
                                                            onChange={e => changeHandler(e.target.value)}
                                                            MenuProps={{ sx: { maxHeight: '320px', padding: '50px' } }}
                                                        >
                                                            <MenuItem dense
                                                                divider
                                                                value={""}
                                                                onClickCapture={(e) => { e.stopPropagation(); e.preventDefault() }}
                                                                onKeyDown={e => e.stopPropagation()}>
                                                                <TextField onChange={(e) => setCari(e.target.value)} value={cari} variant='outlined' label="Cari" className='w-100'
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment>
                                                                                <IconButton>
                                                                                    <SearchOutlinedIcon />
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </MenuItem>
                                                            {
                                                                options?.map((item, index) => {
                                                                    return (
                                                                        <MenuItem key={index} value={item.label} >{item.label}</MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <TextField required variant="outlined" label="lokasi" value={lokasi} className="w-100" onChange={e => setLokasi(e.target.value)} />
                                            </div>
                                       
                                            <div className='col'>
                                                <Button type='button' variant="outlined" color="error" onClick={cancelEdit} className="float-start">Batal</Button>
                                                <Button type='submit' disabled={loading ? true : false} variant="contained" className='float-end'>
                                                    {loading ? <CircularProgress size="1.5rem" className='text-white' /> : edit ? 'Update' : 'Simpan'}
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </Paper>
                            </Container>
                            :
                            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                                <div className='text-end'>
                                    <Button onClick={() => { toggleDrawer() }} variant="contained" className={`float-end ${open ? 'd-none' : 'd-block'}`}>Tambah</Button>
                                </div>
                                <Paper className='bg-card' sx={{ width: '100%', overflow: 'hidden', p: 2 }}>
                                    <TableContainer sx={{ maxHeight: 440 }}>
                                        <Table aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ width: column.width }}
                                                        >
                                                            <strong>{column.label}</strong>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {jurnal
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row) => {
                                                        count += 1;
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                <TableCell>
                                                                    {count}
                                                                </TableCell>
                                                                {columns.slice(1, 3).map((column) => (
                                                                    <TableCell>
                                                                        {row[column.id]}
                                                                    </TableCell>
                                                                ))}
                                                                <TableCell>
                                                                    {
                                                                        loading === row.id ? <Button variant='text'>
                                                                            <CircularProgress size="1.5rem" />
                                                                        </Button> : <Button variant='text' onClick={() => { editData(row.id) }}>
                                                                            <EditIcon />
                                                                        </Button>
                                                                    }
                                                                    <Button variant='text' color='error' onClick={(event) => { deleteData(event, row.id) }}><DeleteIcon /></Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={jurnal.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Paper>
                                <Footer />

                            </Container>
                    }

                </Box>
            </Box >
        </ThemeProvider >
    );
}

export default Award