import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Navbar from './nav/Navbar';
import Footer from '../main/nav/Footer';
import { Button, Divider, Typography } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { apiContext } from '../../api';
import useAuth from '../main/Auth/useAuth';
import { Link } from 'react-router-dom';
import BarChartIcon from '@mui/icons-material/BarChart';
import SsidChartIcon from '@mui/icons-material/SsidChart';
const mdTheme = createTheme();

function DashboardContent() {
    const { axios, API_URL } = React.useContext(apiContext);
    const { user, profile } = useAuth();
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const user_id = localStorage.getItem("user_id");
    const [publikasi, setPublikasi] = React.useState([]);
    const fetchPublikasi = async () => {
        const response = await axios.get(`/publikasi-get/${user_id}`);
        const data = response.data;
        if (data) {
            setPublikasi(data);
        }

    }
    React.useEffect(() => {
        profile();
        fetchPublikasi();
    }, [])
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar title={'Dashboard'} />

                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={8} lg={9}>
                                <Paper
                                    className='bg-card'
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography component="h2" variant="h5" className='main-color'>
                                        Tentang Saya
                                    </Typography>
                                    <Divider className='my-2 main-color' />

                                    <Typography variant="subtitle1" paragraph color="text.secondary" style={{ textAlign: 'justify' }}>
                                     {user.profile?.about}
                                    </Typography>

                                </Paper>
                            </Grid>

                            <Grid item xs={12} md={4} lg={3}>
                                <Paper
                                    className='bg-card'
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {user.profile?.foto_fsd ? <img src={API_URL + '/' + user.profile?.foto_fsd} className="w-75 h-75 mx-auto" /> : <AccountBoxIcon className="w-75 h-75 mx-auto" />}
                                    <div className='text-center mt-2'>
                                        <strong>{user.biodata?.gelar_depan} {user.nama}, {user.biodata?.gelar_belakang}</strong>
                                    </div>
                                    {
                                        user.nip && <div className='text-center'>
                                            ({user.nip})
                                        </div>
                                    }
                                </Paper>
                            </Grid>
                        </Grid>
                        <Footer />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default function Dashboard() {
    return <DashboardContent />;
}