import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import unsam_logo from '../../../assets/img/unsam.png'
import { Typography } from '@mui/material';
function Header({ jurnalRef,
    bukuRef,
    prosidingRef,
    awardRef,
    workshopRef }) {
    const executeScroll = (ref) => ref.current.scrollIntoView()
    return (
        <React.Fragment>
            <Toolbar component="nav"
                variant="dense"
                className='header-color'
                sx={{justifyContent: 'space-between', overflowX: 'auto', py: 0.5 }} xs={{ borderBottom: 1, borderColor: 'divider',  position: 'fixed', top: 0, width: '100%', py: 0.5 }} >
                <Container maxWidth="lg" >
                    <div className='row'>
                        <div className='col-lg-5'>
                            <img src={unsam_logo} width="60px" alt='logo' className='me-2' />
                            <Typography component="h5" variant="h5" sx={{color:'white', display: { xs: 'none', sm: 'inline-block' } }}>
                                Faculty and Staff Dashboard
                            </Typography>
                        </div>
                    </div>
                </Container>
            </Toolbar>

        </React.Fragment >
    );
}

export default Header;