import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { Divider, TextField } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload'
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import '../../assets/css/app.css'
import ContactPageIcon from '@mui/icons-material/ContactPage';
import Navbar from './nav/Navbar';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Footer from '../main/nav/Footer';
import { apiContext } from '../../api';
import useAuth from '../main/Auth/useAuth';

const mdTheme = createTheme();

function ProfilContent() {
    const { axios, API_URL } = React.useContext(apiContext);
    const user_id = localStorage.getItem("user_id");
    const [open, setOpen] = React.useState(true);
    const { user } = useAuth();
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [editId, setEditId] = React.useState("");
    const [file, setFile] = React.useState("");
    const [icon, setIcon] = React.useState("");
    const [image, setImage] = React.useState(user.profile?.foto_fsd ? API_URL+'/'+user.profile?.foto_fsd: "");
    const [iconImage, setIconImage] = React.useState("");
    const [link, setLink] = React.useState("");
    const [publikasi, setPublikasi] = React.useState([]);
    const [about, setAbout] = React.useState(user.profile?.about? user.profile.about:'');
    const inputFile = React.useRef(null);
    const iconFile = React.useRef(null);
    const [showModal, setShowModal] = React.useState(false);
    const handleFileUpload = e => {
        const { files } = e.target;
        setImage(URL.createObjectURL(files[0]));
        setFile(files[0]);
    };
    const handleIconUpload = e => {
        const { files } = e.target;
        setIconImage(URL.createObjectURL(files[0]));
        setIcon(files[0]);
    };
    const edit = (data) => {
        setLink(data.link);
        setEditId(data.id);
        setIconImage(API_URL + data.img);
    }
    const fetchPublikasi = async () => {
        const response = await axios.get(`/publikasi-get/${user_id}`);
        const data = response.data;
        if (data) {
            setPublikasi(data);
        }

    }
    const onButtonClick = () => {
        inputFile.current.click();
    };
    const iconInput = () => {
        iconFile.current.click();
    };
    React.useEffect(() => {
        fetchPublikasi();
    }, [])
    const resetPublikasi = () => {
        setIconImage("");
        setLink("");
    }
    const publikasiUpdate = async event => {
        event.preventDefault();
        let formData = new FormData();
        formData.append('img', icon);
        formData.append("user_id", user_id);
        formData.append("link", link);
        await axios.post(`/publikasi-update/${editId}`, formData)
            .then(
                response => {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Berhasil disimpan',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    fetchPublikasi();
                }
            )
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Mohon coba input kembali',
                })

            });
    }
    const deleteData = (event) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Data tidak dapat dikembalikan jika dihapus",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus'
        }).then((result) => {
            if (result.isConfirmed) {
                publikasiDelete(event);
            }
        })

    }
    const publikasiDelete = async (event) => {
        event.preventDefault();
        await axios.delete(`/publikasi-delete/${editId}`)
            .then(
                response => {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Berhasil dihapus',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    fetchPublikasi();
                }
            )
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Mohon coba input kembali',
                })

            });
    }
    const submit = async event => {
        event.preventDefault();
        let data = new FormData();
        data.set('about', about);
        data.append('image', file);
        await axios.post('/profil-post', data)
            .then(
                response => {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Berhasil disimpan',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            )
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Mohon coba input kembali',
                })

            });
    }
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar title={'Profil'} />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>


                            <Grid item xs={12} md={4} lg={3}>
                                <Paper
                                    className='bg-card'
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240,
                                    }}
                                >
                                    <input
                                        style={{ display: "none" }}
                                        accept=".jpg,.png"
                                        ref={inputFile}
                                        onChange={handleFileUpload}
                                        type="file"
                                    />

                                    <Button
                                        variant="outlined" className='align-items-center h-100 d-block' onClick={onButtonClick}>
                                        {image ? <img src={image} className="w-75 h-75" /> : <ContactPageIcon className="w-75 h-75" />}
                                        <br />
                                        <UploadIcon />
                                        Update Foto
                                    </Button>
                                </Paper>
                                <div
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        mt: 2
                                    }}
                                >
                                    <TextField disabled InputLabelProps={{ shrink: true }} className="w-100" label="Email" variant="outlined" value={user.email} />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={8} lg={9}>
                                <form onSubmit={submit}>
                                    <Paper
                                        className='bg-card'
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <div className='row mb-4' style={{rowGap:'12px'}}>
                                            <div className='col-md-4'>
                                                <TextField disabled InputLabelProps={{ shrink: true }} className="w-100" label="Gelar Depan" variant="outlined" value={user.biodata?.gelar_depan} />
                                            </div>
                                            <div className='col-md-4'>
                                                <TextField disabled InputLabelProps={{ shrink: true }} className="w-100" label="Nama" variant="outlined" value={user.biodata.nama} />
                                            </div>
                                            <div className='col-md-4'>
                                                <TextField disabled InputLabelProps={{ shrink: true }} className="w-100" label="Gelar Belakang" variant="outlined" value={user.biodata?.gelar_belakang} />
                                            </div>
                                        </div>
                                        <div className='row mb-4' style={{ rowGap: '12px' }}>
                                            <div className='col-md-4'>
                                                <TextField disabled InputLabelProps={{ shrink: true }} className="w-100" label="Tempat Lahir" variant="outlined" value={user.biodata?.tempat_lahir} />
                                            </div>
                                            <div className='col-md-4'>
                                                <TextField disabled InputLabelProps={{ shrink: true }} className="w-100" label="Unit Kerja" variant="outlined" value={user.biodata?.instansi?.nama} />
                                            </div>
                                            <div className='col-md-4'>
                                                <TextField disabled InputLabelProps={{ shrink: true }} className="w-100" label="NIDN" variant="outlined" value={user.biodata?.nidon} />
                                            </div>
                                        </div>
                                        <div className='row mb-4' style={{ rowGap: '12px' }}>
                                            <div className='col-md-4'>
                                                <TextField disabled InputLabelProps={{ shrink: true }} className="w-100" label="S1" variant="outlined" value={user.pendidikan_s1 ? user.pendidikan_s1?.jurusan + ', ' + user.pendidikan_s1?.nama_sekolah + ', ' + user.pendidikan_s1?.lokasi + '. Tahun: ' + user.pendidikan_s1?.tahun_lulus : ''} />
                                            </div>
                                            <div className='col-md-4'>
                                                <TextField disabled InputLabelProps={{ shrink: true }} className="w-100" label="S2" variant="outlined" value={user.pendidikan_s2 ? user.pendidikan_s2?.jurusan + ', ' + user.pendidikan_s2?.nama_sekolah + ', ' + user.pendidikan_s2?.lokasi + '. Tahun: ' + user.pendidikan_s2?.tahun_lulus : ''} />
                                            </div>
                                            <div className='col-md-4'>
                                                <TextField disabled InputLabelProps={{ shrink: true }} className="w-100" label="S3" variant="outlined" value={user.pendidikan_s3 ? user.pendidikan_s3?.jurusan + ', ' + user.pendidikan_s3?.nama_sekolah + ', ' + user.pendidikan_s3?.lokasi + '. Tahun: ' + user.pendidikan_s3?.tahun_lulus : ''} />
                                            </div>
                                        </div>
                                        <div className='row mb-4' style={{ rowGap: '12px' }}>
                                            <div className='col-md-6'>
                                                <TextField disabled InputLabelProps={{ shrink: true }} className="w-100" label="Link ID Scopus" variant="outlined" value={user.profile?.scopus} />
                                            </div>
                                            <div className='col-md-6'>
                                                <TextField disabled InputLabelProps={{ shrink: true }} className="w-100" label="Link ID Thomson Reuters" variant="outlined" value={user.profile?.thomson} />
                                            </div>

                                        </div>
                                        <div className='row mb-4' style={{ rowGap: '12px' }}>
                                            <div className='col-md-6'>
                                                <TextField disabled InputLabelProps={{ shrink: true }} className="w-100" label="Link ID Google Scholar" variant="outlined" value={user.profile?.google}/>
                                            </div>
                                            <div className='col-md-6'>
                                                <TextField disabled InputLabelProps={{ shrink: true }} className="w-100" label="Link ID Sinta" variant="outlined" value={user.profile?.sinta} />
                                            </div>
                                        </div>
                                        <div className='mb-4' style={{ rowGap: '12px' }}>
                                                <TextField fullWidth multiline rows={4} className="w-100" label="Tentang Saya" variant="outlined" value={about} onChange={(e)=>setAbout(e.target.value)} />
                                        </div>
                                        <div className='row mb-4 ms-1 text-end'>
                                            <Button type="submit" variant="contained" className='w-25 header-color'>Simpan</Button>
                                        </div>
                                  
                                    </Paper>
                                    <Paper
                                        className='bg-card'
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            mt: 2
                                        }}
                                    >
                                    </Paper>
                                </form>
                            </Grid>

                        </Grid>
                        <Footer />

                    </Container>
                </Box>
            </Box>
            <div className="modal fade" id="publikasi-edit" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog" style={{ marginTop: '5rem' }}>
                    <div className="modal-content">
                        <form onSubmit={publikasiUpdate}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Edit Publikasi</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <input
                                    style={{ display: "none" }}
                                    accept=".jpg,.png"
                                    ref={iconFile}
                                    onChange={handleIconUpload}
                                    type="file"
                                />

                                <Button
                                    variant="outlined" className='align-items-center h-100 d-block mx-auto' onClick={iconInput}>
                                    {iconImage ? <img src={iconImage} className="w-75 h-75" /> : <ContactPageIcon className="w-75 h-75" />}
                                    <br />
                                    <UploadIcon />
                                    Logo Publikasi
                                </Button>
                                <TextField className="w-100 mt-2" label="Link Publikasi" value={link} variant="outlined" onChange={(e) => setLink(e.target.value)} placeholder='https://' />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger float-start" data-bs-dismiss="modal" onClick={(event) => { deleteData(event) }}><DeleteIcon /></button>
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Batal</button>

                                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
}

export default function Profil() {
    return <ProfilContent />;
}