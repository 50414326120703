import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './nav/Header';
import Footer from './nav/Footer';
import { Button, Card, CardActionArea, CardContent, CardMedia, Divider, FormControl, InputLabel, Link, MenuItem, Select, Typography } from '@mui/material';
import bg from '../../assets/img/biro_unsam.jpg';
import logo from '../../assets/img/unsam.png';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import '../../assets/css/app.css'
import { useState } from 'react';
import scopus from '../../assets/img/scopus.png'
import thomson from '../../assets/img/thomsonReuters.png'
import google from '../../assets/img/googleScholar.png'
import sinta from '../../assets/img/garuda.png'
import axios from 'axios';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import { Grid } from 'gridjs-react';
import "gridjs/dist/theme/mermaid.css";
import { html } from 'gridjs';
import { apiContext } from '../../api';
import { Image } from '@mui/icons-material';
const theme = createTheme();

export default function Main() {
    const { nama } = useParams();
    const [user, setUser] = useState([]);
    const jurnalRef = React.useRef(null)
    const bukuRef = React.useRef(null)
    const prosidingRef = React.useRef(null)
    const awardRef = React.useRef(null)
    const { API_URL } = React.useContext(apiContext);
    const workshopRef = React.useRef(null)
    const [tahun, setTahun] = React.useState("");
    const [tahunData, setTahunData] = React.useState([]);
    const [publikasi, setPublikasi] = React.useState("Jurnal");
    const [publikasiData, setPublikasiData] = React.useState([]);
    const fetchData = async () => {
        const { data } = await axios.get(`/user/${nama}`);
        if (data) {
            setUser(data);
        }
    }
    const fetchPublikasi = async () => {
        const { data } = await axios.get(`/publikasi/${nama}/${publikasi}`)
        if (data) {
            setPublikasiData(data);
        }
    }
    React.useEffect(() => {
        fetchData();
    }, [])
    React.useEffect(() => {
        fetchPublikasi();
    }, [publikasi, tahun])
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Header title="Blog" className="w-100" />
            <Box >
                <main>
                    <Box
                        sx={{
                            color: '#fff',
                            mb: 4,
                            height: { xs: 220, md: 200 }
                        }}
                        className="header-color"
                    >
                        {<img style={{ display: 'none' }} src={bg} />}

                    </Box>
                    <Box className='row' sx={{ display: 'flex', padding: 2, margin: { sm: '0', md: '4' }, marginTop: { xs: '-17rem', md: '-12rem' }, py: { sm: '0', md: 5 }, padding: { sm: '0', md: '0 130px' } }}>
                        {user.profile ?
                            <Box className='d-flex w-100 ' sx={{ flexWrap: 'wrap', paddingLeft: { xs: '0', md: '1.5rem' }, paddingRight: { xs: '0', md: '1.5rem' } }}>
                                <Box className='col' sx={{ width: { xs: 'auto', md: 180 }, textAlign: { xs: 'center' }, display: { md: 'contents' } }}>
                                    <Box component="img" className='rounded-circle shadow-lg mt-4' src={user.profile?.foto_fsd ? API_URL + '/' + user.profile.foto_fsd : logo} sx={{ width: { xs: 110, md: 160 }, height: { xs: 110, md: 160 } }} />
                                </Box>
                                <div className='col'>
                                    <CardContent sx={{ flex: 1, textAlign: { xs: 'center', md: 'left' } }}>
                                        <div className='p-2 mb-2 d-inline-block bg-white rounded-pill fw-bold px-3'>
                                            <span className='text-capitalize'>{user.biodata?.instansi?.kode_skpd === '02' ? 'Fakultas Hukum' : user.biodata?.instansi?.kode_skpd === '03' ? 'Fakultas Ekonomi' : user.biodata?.instansi?.kode_skpd === '04' ? 'Fakultas Pertanian' : user.biodata?.instansi?.kode_skpd === '05' ? 'Fakultas KIP' : user.biodata?.instansi?.kode_skpd === '06' ? 'Fakultas Teknik' : '-'}</span>
                                        </div>
                                        <Typography className='w-100 text-white' sx={{ fontSize: { xs: 'large', md: 'xx-large' } }}>
                                            {user.biodata?.gelar_depan} {user.nama}{user.biodata?.gelar_belakang ? ' , ' + user.biodata?.gelar_belakang : ''}
                                        </Typography>
                                        <Box className='d-flex' sx={{ gap: '25px', marginTop: { xs: '2rem', md: '1.5rem' }, overflow: { xs: 'auto', md: 'hidden' }, maxWidth: { xs: '348px', md: '100%' } }}>
                                            {
                                                user.profile?.scopus &&
                                                <a href={user.profile.scopus} target="_blank" className='text-decoration-none'> <img src={scopus} alt="scopus" width="60" /></a>
                                            }
                                            {
                                                user.profile?.thomson &&
                                                <a href={user.profile.thomson} target="_blank" className='text-decoration-none'>  <img src={thomson} alt="thomson" width="110" /></a>
                                            }
                                            {
                                                user.profile?.google &&
                                                <a href={user.profile.google} target="_blank" className='text-decoration-none'> <img src={google} alt="google" width="70" /></a>
                                            }
                                            {
                                                user.profile?.sinta &&
                                                <a href={user.profile.sinta} target="_blank" className='text-decoration-none'> <img src={sinta} alt="sinta" width="110" /></a>
                                            }
                                        </Box>
                                    </CardContent>
                                </div>

                                <Box sx={{ marginLeft: { xs: '1.5rem', md: '3rem' }, marginRight: { xs: '1.5rem', md: '3rem' } }}>
                                    <Typography component="h4" className=' mb-4'>
                                        <AttachEmailIcon /> <span className='text-primary '>{user.email}</span>
                                    </Typography>
                                    <Typography component="h2" variant="h5" className='fw-bold my-4'>
                                        Tentang Saya
                                    </Typography>
                                    <Typography variant="subtitle1" paragraph className='mb-4' style={{ textAlign: 'justify' }}>
                                        {user.profile?.about}
                                    </Typography>
                                    <Typography component="h2" variant="h5" className='mb-4 mt-4 fw-bold'>
                                        Publikasi
                                    </Typography>
                                    <Box className='flex-row-reverse d-flex' sx={{ marginBottom: { xs: '0', md: '-50px' }, gap: '30px' }}>
                                        <FormControl size="small" sx={{ width: { xs: '100%', md: 'auto' } }}>
                                            <InputLabel>Publikasi</InputLabel>
                                            <Select
                                                label="Publikasi"
                                                defaultValue=""
                                                value={publikasi}
                                                sx={{ zIndex: '2', width: { xs: '100%', md: '200px' } }}
                                                InputLabelProps={{
                                                    style: {
                                                        height: '44'
                                                    },
                                                }}
                                                onChange={e => setPublikasi(e.target.value)}
                                            >
                                                <MenuItem value={"Jurnal"}>Jurnal</MenuItem>
                                                <MenuItem value={"Prosiding"}>Prosiding</MenuItem>
                                                <MenuItem value={"Workshop"}>Workshop</MenuItem>
                                                <MenuItem value={"Award"}>Award</MenuItem>
                                                <MenuItem value={"Buku"}>Buku</MenuItem>
                                                <MenuItem value={"Karya"}>Karya</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    {
                                        publikasiData ?
                                            <Grid
                                                data={
                                                    publikasiData?.map((item, index) =>
                                                        [index += 1,
                                                        item.tipe === 'workshop' ?
                                                            html(`${item.tanggal}, <span>${item.judul}, ${item.nama}, ${item.lokasi}.</span>`) :
                                                            item.tipe === 'award' ?
                                                                html(`${item.tanggal}, <span>${item.judul}, ${item.nama}, ${item.lokasi}, ${item.negara}.</span>`) :
                                                                html(`<strong>${item.nama}</strong>, <span>${item.tahun}, ${item.url ? `<a href=${item.url}>${item.judul}</a>` : item.judul} , ${item.jurnal}, Vol. ${item.volume}, ${item.halaman}.</span>`)
                                                        ]
                                                    )
                                                }
                                                columns={['No', {
                                                    name: publikasi,
                                                    width: '100%',
                                                }]}
                                                style={{
                                                    th: {
                                                        'background-color': '#001525',
                                                        'color': 'white',
                                                    },
                                                }}
                                                search={true}
                                                pagination={{
                                                    enabled: true,
                                                    limit: 10,
                                                }}
                                            /> : <></>
                                    }

                                    {user.jurnal ? <>
                                        <Typography ref={jurnalRef} component="h2" variant="h6" color="text.secondary" className='mb-3'>
                                            jurnal
                                        </Typography >
                                        <div className='ms-2'>
                                            <Typography component="p" color="text.secondary">
                                                {user.jurnal.map((item) => {
                                                    return (
                                                        <p key={item.id}><strong>{item.nama}</strong>, <span>{item.tahun}, {item.link ? <a href={item.link}>{item.judul}</a> : item.judul}, {item.jurnal}, Vol. {item.volume}, {item.halaman}</span></p>
                                                    )
                                                })}
                                            </Typography >
                                        </div>
                                        <Divider className='my-3' />
                                    </> : ""
                                    }
                                    {user.prosiding ? <>
                                        <Typography ref={prosidingRef} component="h2" variant="h6" color="text.secondary" className='mb-3'>
                                            Prosiding
                                        </Typography >
                                        <div className='ms-2'>
                                            <Typography component="p" color="text.secondary">
                                                {user.prosiding.map((item) => {
                                                    return (
                                                        <p key={item.id}><strong>{item.nama}</strong>, {item.tahun}, {item.link ? <a href={item.link}>{item.judul}</a> : item.judul}, {item.lainya}</p>
                                                    )
                                                })}
                                            </Typography >
                                        </div>
                                        <Divider className='my-3' />
                                    </> : ""
                                    }
                                    {/* {Object.keys(workshop).length ? <>
                                    <Typography ref={workshopRef} component="h2" variant="h6" color="text.secondary" className='mb-3'>
                                        Workshop
                                    </Typography >
                                    <div className='ms-2'>
                                        <Typography component="p" color="text.secondary">
                                            {workshop.map((item) => {
                                                return (
                                                    <p key={item.id}>{item.tahun}, <i>{item.nama}</i>, {item.penyelenggara}, {item.lokasi}</p>
                                                )
                                            })}
                                        </Typography >
                                    </div>
                                    <Divider className='my-3' />
                                </> : ""
                                } */}
                                    {user.buku ? <>
                                        <Typography ref={bukuRef} component="h2" variant="h6" color="text.secondary" className='mb-3'>
                                            Buku
                                        </Typography >
                                        <div className='ms-2'>
                                            <Typography component="p" color="text.secondary">
                                                {user.buku.map((item) => {
                                                    return (
                                                        <p key={item.id}><strong>{item.nama}</strong>, {item.tahun}, <i>{item.link ? <a href={item.link}>{item.judul}</a> : item.judul}</i>, {item.jurnal}, Vol. {item.volume}, {item.halaman}</p>
                                                    )
                                                })}
                                            </Typography >
                                        </div>
                                        <Divider className='my-3' />
                                    </> : ""
                                    }
                                    {/* {Object.keys(award).length ? <>
                                    <Typography ref={awardRef} component="h2" variant="h6" color="text.secondary" className='mb-3'>
                                        Penghargaan
                                    </Typography >
                                    <div className='ms-2'>
                                        <Typography component="p" color="text.secondary">
                                            {award.map((item) => {
                                                return (
                                                    <p key={item.id}>{item.tahun}, {item.nama}, {item.oleh}, {item.negara}</p>
                                                )
                                            })}
                                        </Typography >
                                    </div>
                                    <Divider className='my-3' />
                                </> : ""
                                } */}
                                </Box>

                            </Box>
                            : ''
                        }
                    </Box>
                </main>
            </Box>
            <Footer
            />
        </ThemeProvider>
    );
}